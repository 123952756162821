import { ref, watch, computed } from '@vue/composition-api';
import store from '@/store';

// Notification
import { useToast } from 'vue-toastification/composition';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

export default function useUsersList() {
  // Use toast
  const toast = useToast();

  const refUserListTable = ref(null);

  // Table Handlers
  const tableColumns = [
    { key: 'code', label: 'Mã HĐ', thStyle: 'padding: 0.72rem 1rem', tdClass: 'p-1' },
    { key: 'payroll', label: 'Công ty', thStyle: 'padding: 0.72rem 1rem', tdClass: 'p-1' },
    { key: 'amount', label: 'Số tiền', thStyle: 'padding: 0.72rem 1rem', tdClass: 'p-1' },
    { key: 'status', label: 'Trạng thái', thStyle: 'padding: 0.72rem 1rem', tdClass: 'p-1' },
    { key: 'employeeName', label: 'Người tạo', thStyle: 'padding: 0.72rem 1rem', tdClass: 'p-1' },
    { key: 'createdAt', label: 'Ngày tạo', thStyle: 'padding: 0.72rem 1rem', tdClass: 'p-1' },
    {
      key: 'performedAt',
      label: 'Ngày thực hiện',
      thStyle: 'padding: 0.72rem 1rem',
      tdClass: 'p-1',
    },
    { key: 'documents', label: 'UNC', thStyle: 'padding: 0.72rem 1rem', tdClass: 'p-1' },
    { key: 'actions', label: '' },
  ];
  const perPage = ref(10);
  const totalContract = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const companyName = ref(null);
  const typeFilter = ref(null);
  const statusFilter = ref(0);
  const startDate = ref(null);
  const endDate = ref(null);
  const code = ref(null);
  // const searchQuery = ref('');
  // const sortBy = ref('id');
  // const isSortDirDesc = ref(true);
  // const roleFilter = ref(null);
  // const planFilter = ref(null);
  // const statusFilter = ref(null);

  const dataMeta = computed(() => {
    const localItemsCount = refUserListTable.value ? refUserListTable.value.localItems.length : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalContract.value,
    };
  });

  const refetchData = () => {
    refUserListTable.value.refresh();
  };

  watch(
    [currentPage, perPage, statusFilter, typeFilter, startDate, endDate, code, companyName],
    () => {
      refetchData();
    },
  );

  const fetchPayrollPayment = (ctx, callback) => {
    store
      .dispatch('app-payroll/fetchPayrollsPayment', {
        code: code.value,
        limit: perPage.value,
        page: currentPage.value,
        status: statusFilter.value,
        type: typeFilter.value,
        createdAt: startDate.value,
        endDate: endDate.value,
        companyName: companyName.value,
      })
      .then((response) => {
        const { data, meta } = response.data;
        callback(data);
        totalContract.value = meta.total;
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Lỗi tải danh sách thanh toán',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        });
      });
  };

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveUserStatusVariant = (value) => {
    if (value === 0) return 'info';
    if (value === 1 || value === 2 || value === 3) return 'warning';
    if (value === 4) return 'primary';
    if (value === 5) return 'danger';
    return 'primary';
  };
  const resolveStatus = (value) => {
    if (value === 0) return 'Chưa xác thực';
    if (value === 1 || value === 2 || value === 3) return 'Đang xử lý';
    if (value === 4) return 'Thành công';
    if (value === 5) return 'Thất bại';
    return '';
  };

  const statusOptions = [
    { label: 'Chưa xác thực', value: 0 },
    { label: 'Đang xử lý', value: '1,2,3' },
    { label: 'Thành công', value: 4 },
    { label: 'Thất bại', value: 5 },
  ];

  const typeOptions = [
    { label: 'Khoản ứng', value: 0 },
    { label: 'Lead', value: 2 },
  ];
  return {
    fetchPayrollPayment,
    tableColumns,
    perPage,
    currentPage,
    totalContract,
    dataMeta,
    perPageOptions,
    refUserListTable,
    statusOptions,
    typeOptions,
    statusFilter,
    typeFilter,
    resolveUserStatusVariant,
    refetchData,
    resolveStatus,
    code,
    startDate,
    companyName,
  };
}
