<template>
  <b-card no-body>
    <b-card-body>
      <b-row class="align-items-end">
        <b-col cols="12" md="2" class="mb-md-0 mb-2">
          <label>Mã hợp đồng</label>
          <b-form-input
            class="d-inline-block mr-1"
            :value="code"
            @input="(val) => debounceSearch('code', val)"
          />
        </b-col>
        <b-col cols="12" md="2" class="mb-md-0 mb-2">
          <label>Tên công ty</label>
          <b-form-input
            class="d-inline-block mr-1"
            :value="companyName"
            @input="(val) => debounceSearch('companyName', val)"
          />
        </b-col>
        <b-col cols="12" md="2" class="mb-md-0 mb-2">
          <label>Trạng thái</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="statusFilter"
            :options="statusOptions"
            class="w-100"
            :reduce="(val) => val.value"
            @input="(val) => $emit('update:statusFilter', val)"
          />
        </b-col>
        <b-col cols="12" md="2" class="mb-md-0 mb-2">
          <label>Ngày đăng ký</label>
          <b-form-datepicker
            placeholder=""
            locale="en-GB"
            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
            today-button
            close-button
            @input="(val) => $emit('update:startDate', val)"
          />
        </b-col>
        <b-col cols="12" md="2">
          <b-button variant="outline-primary" @click="handleCancel()">Hủy tìm kiếm</b-button>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BFormDatepicker,
} from 'bootstrap-vue';
import vSelect from 'vue-select';

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BFormInput,
    BButton,
    BFormDatepicker,
    vSelect,
  },
  props: {
    statusFilter: {
      type: Number,
      default: null,
    },
    statusOptions: {
      type: Array,
      default: () => [],
    },
    code: {
      type: String,
      default: '',
    },
    companyName: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      debounce: null,
    };
  },
  methods: {
    debounceSearch(id, val) {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.$emit(`update:${id}`, val);
      }, 1000);
    },
    handleCancel() {
      this.$emit('update:code', null);
      this.$emit('update:statusFilter', null);
      this.$emit('update:companyName', null);
      this.$emit('update:startDate', null);
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
