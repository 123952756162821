<template>
  <div>
    <payment-filter
      :code.sync="code"
      :start-date.sync="startDate"
      :company-name.sync="companyName"
      :status-options="statusOptions"
      :status-filter.sync="statusFilter"
    />
    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="6"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
          </b-col>
          <b-col cols="6" md="6" class="d-flex align-items-center justify-content-end mb-1 mb-md-0">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalContract"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refUserListTable"
        class="position-relative"
        :items="fetchPayrollPayment"
        :busy="busy"
        responsive
        :fields="tableColumns"
        show-empty
        empty-text="No matching records found"
      >
        <!-- Column: customer -->
        <template #cell(amount)="data">
          <div>{{ numberWithCommas(data.item.amount) }}</div>
        </template>
        <template #cell(payroll)="data">
          <div v-if="data.item.payroll">{{ data.item.payroll.companyName }}</div>
        </template>
        <template #cell(code)="data">
          <div v-if="data.item.payroll">{{ data.item.payroll.code }}</div>
        </template>
        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge
            pill
            :variant="`light-${resolveUserStatusVariant(data.item.status)}`"
            class="text-capitalize"
          >
            {{ resolveStatus(data.item.status) }}
          </b-badge>
        </template>
        <template #cell(createdAt)="data">
          <format-time :time="data.item.createdAt" />
        </template>
        <template #cell(performedAt)="data">
          <format-time :time="data.item.performedAt" />
        </template>
        <template #cell(documents)="data">
          <div v-for="(unc, idx) in data.item.documents" :key="idx" style="padding: 3px 0">
            <a :href="unc" target="_blank" style="text-decoration: underline">UNC_{{ idx }}</a>
          </div>
        </template>
        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <div class="d-flex">
            <b-button
              v-if="
                $can(
                  permissionCode.PAYROLL_CONFIRM_DISBURSE.action,
                  permissionCode.PAYROLL_CONFIRM_DISBURSE.resource,
                )
              "
              variant="warning"
              style="margin: 5px"
              @click="handleConfirm(data.item)"
              >Xác nhận</b-button
            >
            <b-button
              v-if="
                $can(
                  permissionCode.PAYROLL_CONFIRM_DISBURSE.action,
                  permissionCode.PAYROLL_CONFIRM_DISBURSE.resource,
                )
              "
              variant="secondary"
              style="margin: 5px"
              @click="handleCancel(data.item.id)"
              >Huỷ</b-button
            >
          </div>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalContract"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <b-modal
      ref="modal-repayment"
      title="Ghi nhận thanh toán"
      @ok="confirmPayment()"
      @hidden="resetModal()"
    >
      <b-row class="align-items-end">
        <b-col cols="12" md="12" class="mb-1">
          <label>Mã transaction</label>
          <b-form-input v-model="statementCode" class="d-inline-block mr-1" />
        </b-col>
        <b-col cols="12" md="12" class="mb-1">
          <label>Số tiền</label>
          <b-form-input v-model="formattedValue" class="d-inline-block mr-1" />
        </b-col>
        <b-col cols="12" md="12" class="mb-1">
          <label>Ngày thanh toán</label>
          <flat-pickr
            v-model="performedAt"
            class="form-control"
            :config="{
              enableTime: true,
              enableSeconds: true,
              allowInput: true,
              time_24hr: true,
              altInput: true,
              altFormat: 'd/m/Y - H:i:S',
              dateFormat: 'Y-m-d H:i:S',
            }"
          />
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BModal,
} from 'bootstrap-vue';
import vSelect from 'vue-select';
import { VueAutosuggest } from 'vue-autosuggest';
import * as moment from 'moment';
import FormatTime from '@core/components/format-time/FormatTime.vue';
import store from '@/store';
import { onUnmounted } from '@vue/composition-api';
import { avatarText } from '@core/utils/filter';
import permissionCode from '@/constants/permissionCode';
import { repaymentCancel, repaymentConfirm } from '@/apis/apiPayroll';
import { numberWithCommas, numberWithoutCommas } from '@core/utils/utils';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import flatPickr from 'vue-flatpickr-component';
import PaymentFilter from './PayrollPaymentListFilter.vue';
import usePayrollPaymentList from './usePayrollPaymentList';
import payrollStoreModule from '../payrollStoreModule';

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BModal,
    vSelect,
    VueAutosuggest,
    PaymentFilter,
    FormatTime,

    flatPickr,
  },
  setup() {
    const PAYROLL_APP_STORE_MODULE_NAME = 'app-payroll';
    // Register module
    if (!store.hasModule(PAYROLL_APP_STORE_MODULE_NAME)) {
      store.registerModule(PAYROLL_APP_STORE_MODULE_NAME, payrollStoreModule);
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PAYROLL_APP_STORE_MODULE_NAME)) {
        store.unregisterModule(PAYROLL_APP_STORE_MODULE_NAME);
      }
    });
    const {
      fetchPayrollPayment,
      tableColumns,
      perPage,
      currentPage,
      totalContract,
      dataMeta,
      perPageOptions,
      refetchData,
      refUserListTable,
      // UI
      resolveUserStatusVariant,
      // filter
      statusFilter,
      typeFilter,
      statusOptions,
      typeOptions,
      resolveStatus,
      code,
      startDate,
      companyName,
    } = usePayrollPaymentList();
    return {
      // Sidebar
      refUserListTable,
      fetchPayrollPayment,
      tableColumns,
      perPage,
      currentPage,
      totalContract,
      dataMeta,
      perPageOptions,
      refetchData,

      // Filter
      avatarText,
      statusOptions,
      typeOptions,
      typeFilter,
      statusFilter,
      // UI
      resolveUserStatusVariant,
      resolveStatus,
      numberWithCommas,
      code,
      startDate,
      companyName,
      moment,
      permissionCode,
    };
  },
  data() {
    return {
      transactionId: '',
      amount: '',
      statementCode: '',
      performedAt: null,
      limit: 10,
      selected: null,
      busy: false,
    };
  },
  computed: {
    formattedValue: {
      get() {
        return numberWithCommas(this.amount);
      },
      set(newValue) {
        this.amount = numberWithoutCommas(newValue);
      },
    },
  },
  methods: {
    resetModal() {
      this.statementCode = '';
    },
    handleConfirm(data) {
      this.transactionId = data.id;
      this.amount = data.amount;
      this.performedAt = data.performedAt;

      this.$refs['modal-repayment'].show();
    },
    confirmPayment() {
      this.busy = true;
      repaymentConfirm(this.transactionId, {
        amount: this.amount,
        statementCode: this.statementCode,
        performedAt: this.performedAt,
      })
        .then(() => {
          this.busy = false;
          setTimeout(() => {
            this.refetchData();
          }, 50);
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Ghi nhận thanh toán thành côngÏ',
              icon: 'CoffeeIcon',
              variant: 'success',
            },
          });
        })
        .catch((err) => {
          console.log(err.response.data);
          this.busy = false;
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Ghi nhận thanh toán thất bại',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: err.response.data.message,
            },
          });
        });
    },
    handleCancel(id) {
      repaymentCancel(id)
        .then(() => {
          this.refetchData();
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Huỷ thành công',
              icon: 'CoffeeIcon',
              variant: 'success',
            },
          });
        })
        .catch((err) => {
          console.log(err.response.data);
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Huỷ thất bại',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: err.response.data.message,
            },
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-autosuggest.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
